.dev-icons {
     padding-left: 0;
     list-style: none;
     font-size: 3rem;
     margin-bottom: 0px;
     margin-top: 0px;
     text-align: center;
   }

   .logo_img1 {
    margin: 10px;
    width: 100px;
    height: 50px;
  }

  .logo_img2 {
    margin: 10px;
    width: 90px;
    height: 40px;
  }
   
   .competitive-sites-inline {
     display: inline-block;
     margin-right: 20px;
     margin-bottom: 20px;
     font-family: "Google Sans Regular";
   }
   
   .competitive-sites-inline > i {
     color: #868e96;
   }
   
   .competitive-sites-inline > i:hover {
     color: #645beb;
   }

   @media (max-width: 768px) {
    .logo_img1 {
      margin: 10px;
      width: 70px;
      height: 30px;
    }
  
    .logo_img2 {
      margin: 10px;
      width: 60px;
      height: 25px;
    }
   }
   