#topButton {
     visibility: hidden;
     position: fixed;
     bottom: 1.2rem;
     right: 1.5rem;
     cursor: pointer;
     padding: 15px;
     outline: none;
     border-radius: 2rem;
     font-size: 1.5rem;
   }
   