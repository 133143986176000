.repo-card-div {
     color: rgb(88, 96, 105);
     background-color: rgb(255, 255, 255);
     box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
     padding: 2rem;
     cursor: pointer;
   }
   .repo-card-div:hover {
     box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
   }
   
   .repo-stats {
     display: flex;
     -webkit-box-pack: justify;
     justify-content: space-between;
     font-size: 13px;
     color: rgb(106, 115, 125);
   }
   
   .repo-left-stat {
     -webkit-box-flex: 1;
     flex-grow: 1;
     display: flex;
   }
   
   .language-color {
     width: 10px;
     height: 10px;
     background-color: blue;
     margin-right: 0.25rem;
     border-radius: 100%;
   }
   
   .repo-left-stat span {
     display: flex;
     -webkit-box-align: center;
     align-items: center;
     margin-right: 0.75rem;
   }
   
   .repo-name-div {
     display: flex;
     align-items: center;
   }
   
   .repo-svg {
     margin-right: 0.5rem;
     min-width: 16px;
   }
   
   .repo-name {
     white-space: nowrap;
     text-overflow: ellipsis;
     color: rgb(36, 41, 46);
     font-family: "Google Sans Regular";
     margin-bottom: 0.75rem;
     font-size: 25px;
     font-weight: 700;
     letter-spacing: -0.5px;
     overflow: hidden;
     line-height: 1.2;
     margin: 0px;
   }
   
   .repo-star-svg {
     margin-right: 0.3rem;
   }
   
   .repo-description {
     overflow: hidden;
     text-align: left;
     display: -webkit-box;
     font-family: "Google Sans Regular";
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     margin-top: 0.8rem;
   }
   
   .repo-details {
     display: flex;
     flex-direction: row;
   }
   
   .repo-creation-date {
     margin: 0;
     font-family: "Google Sans Regular";
     font-weight: 400;
     margin-left: 0px;
     margin-right: auto;
   }
   
   .repo-languages {
     margin-left: auto;
     margin-right: 0px;
   }
   
   @media (max-width: 768px) {
     .repo-name {
       font-size: 16px;
     }
   
     .repo-description {
       font-size: 14px;
     }
   
     .repo-details {
       flex-direction: column;
     }
   
     .repo-creation-date {
       font-size: 14px;
     }
   }
   