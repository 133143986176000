.experience-accord {
  height:100px;
     margin: 50px;
   }
   
   .accord-panel {
     font-family: "Google Sans Regular";
   }
  
    .body-header-duration2 {
      margin-top: 70px;
      
     width: 100%;
   }
   .duration2 {
     font-family: "Google Sans Regular";
     font-size: 16px;
     color: #212121;
   }
   .card-img2 {
     margin-bottom: 20px;
     border-radius: 50%;
     padding: 10px 27px;
     border: 1px solid #d9dbdf;
     background-color: #6c63ff; 
      background: transparent;
     box-shadow: 5px 5px 5px #d9dbdf;
   }
   @media (max-width: 768px) {
     .experience-accord {
       margin: 20px;
     }
   }
   