.experience-card {
     width: 100%;
     margin-bottom: 20px;
     border-radius: 10px;
     display: flex;
     flex-direction: row;
   }
   
   .experience-card-logo-div {
     margin: 10px;
   }
   
   .experience-card-body-div {
     width: 100%;
   }
   
   .experience-card-header-div {
     display: flex;
     flex-direction: row;
   }
   
   .experience-card-logo {
     width: 70px;
   }
   
   .experience-card-title {
     font-family: "Google Sans Regular";
     margin-top: 10px;
     margin-bottom: 2px;
     margin-left: 0px;
     margin-right: 7px;
     text-align: left;
   }
   
   .experience-card-company {
     font-family: "Google Sans Regular";
     margin: 0;
     text-align: left;
   }
   
   .experience-card-duration {
     font-family: "Google Sans Regular";
     margin-top: 10px;
     margin-bottom: 2px;
     margin-left: 0px;
     margin-right: 0px;
     text-align: right;
     color: #868e96;
   }
   
   .experience-card-heading-left {
     float: left;
     margin-left: 10px;
     margin-right: auto;
   }
   
   .experience-card-heading-right {
     float: right;
     margin-left: auto;
     margin-right: 10px;
   }
   
   .experience-card-location {
     font-family: "Google Sans Regular";
     margin: 0;
     text-align: right;
     color: #868e96;
   }
   
   .experience-card-description {
     margin-top: 5px;
     margin-left: 10px;
     text-align: justify;
     margin-right: 20px;
     font-family: "Google Sans Regular";
   }
   
   .experience-card-company > a {
     position: relative;
     color: #000;
     text-decoration: none;
   }
   
   .experience-card-company > a:hover {
     color: #000;
   }
   
   .experience-card-company > a:hover:before {
     visibility: visible;
     -webkit-transform: scaleX(1);
     transform: scaleX(1);
   }
   
   .experience-card-company > a:before {
     content: "";
     position: absolute;
     width: 100%;
     height: 1px;
     bottom: 0;
     left: 0;
     background-color: #000;
     visibility: hidden;
     -webkit-transform: scaleX(0);
     transform: scaleX(0);
     -webkit-transition: all 0.3s ease-in-out 0s;
     transition: all 0.3s ease-in-out 0s;
   }
   
   @media (max-width: 768px) {
     .experience-card {
       margin-bottom: 10px;
       flex-direction: column;
       text-align: center;
     }
   
     .experience-card-logo {
       width: 100px;
     }
   
     .experience-card-body-div {
     }
   
     .experience-card-heading-left {
       float: center;
       margin-left: auto;
       margin-right: auto;
     }
   
     .experience-card-heading-right {
       float: center;
       margin-left: auto;
       margin-right: auto;
     }
   
     .experience-card-title {
       text-align: center;
     }
   
     .experience-card-company {
       text-align: center;
     }
   
     .experience-card-duration {
       margin-top: 2px;
       text-align: center;
     }
   
     .experience-card-location {
       /* text-align: left; */
       text-align: center;
     }
   
     .experience-card-header-div {
       flex-direction: column;
     }
   
     .experience-card-description {
       margin-left: 14px;
       margin-right: 14px;
       text-align: justify;
     }
   }
   