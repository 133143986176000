.footer-text {
     text-align: center;
     /* color: #868e96; */
     font-weight: bold;
     font-family: "Google Sans Regular";
   }
   
   .footer-div {
     margin-top: 4rem;
     margin-bottom: 2rem;
   }
   